import { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FeaturesSidebar from "../components/Features_sidebar";

function Features() {
  const [visibleDiv, setVisibleDiv] = useState(1);


  const onPress = (e) => {
    e.preventDefault();
    const target = window.document.getElementById(
      e.currentTarget.href.split("#")[1]
    );
    if (target) {
      var headerOffset = 0;
      var elementPosition = target.getBoundingClientRect().top;
      var offsetPosition = elementPosition - headerOffset;

      window.scrollBy({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const handleScroll = (event) => {
      const deltaY = event.deltaY;
      var newVisibleDiv = visibleDiv;
      // Scrolling down
      if (deltaY > 0) {
        newVisibleDiv = newVisibleDiv + 1 > 3 ? 3 : newVisibleDiv + 1;
        setVisibleDiv(newVisibleDiv);
      }
      // Scrolling up
      if (deltaY < 0) {
        newVisibleDiv = newVisibleDiv - 1 < 1 ? 1 : newVisibleDiv - 1;
        setVisibleDiv(newVisibleDiv);
      }
    };

    document.addEventListener("wheel", handleScroll, { passive: false });

    return () => {
      document.removeEventListener("wheel", handleScroll);
    };
  }, [visibleDiv]);



  const settings = {
    dots: false,
    infinite: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <div>
      <div className="lg:block hidden">
        <FeaturesSidebar />
      </div>
      <div className="md:block lg:hidden sm:block">
        <Slider {...settings}>
          <div>
            <div className={`w-full h-screen bg-[#E5E5E5]`}>
              <div className="flex justify-between items-center py-8 px-6 fixed w-screen z-10">
                <div>
                  <img src={require("../assets/images/black_logo.png")} alt="logo" width={50} height={50}
                    className="w-[50px] h-[50px]" />
                </div>
                <div>
                  <span className="text-[32px] font-extrabold font-nationalBold">Features</span>
                </div>
                <div>
                  <div className="flex justify-end">
                    <a href="/" className="bg-white rounded-full w-[40px] h-[40px] text-center">
                      <span className="text-3xl text-black align-super">&times;</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="flex justify-center items-center h-screen">
                <img src={require("../assets/images/feature_1.png")} alt="feature 1" className="px-6" />
              </div>
              <div className="md:py-10 py-8 md:px-6 px-4 bg-black block fixed bottom-0 text-left w-screen md:h-[300px] h-[350px]">
                <span className="md:text-[56px] text-[32px] text-national_2bold font-extrabold leading-[108%] text-white">Linq is Adventure Buddy</span>
                <div className="md:mt-6 mt-4 mb-10">
                  <span className="text-base font-inter font-normal leading-[140%] text-white">
                    Users could create or join group chats and meetups on the Map, allowing them to connect with other users in their area who share similar interests.
                  </span>
                </div>
              </div>
              <div className="fixed bottom-[40px] gap-[11px] px-6 flex w-screen">
                <div className="h-[2px] bg-white w-1/3 rounded"></div>
                <div className="h-[2px] bg-white w-1/3 rounded opacity-30"></div>
                <div className="h-[2px] bg-white w-1/3 rounded opacity-30"></div>
              </div>
            </div>
          </div>
          <div>
            <div className={`w-full h-screen bg-[#F9EFE0]`}>
              <div className="flex justify-between items-center py-8 px-6 fixed w-screen z-10">
                <div>
                  <img src={require("../assets/images/green_logo.png")} alt="logo" width={50} height={50}
                    className="w-[50px] h-[50px]" />
                </div>
                <div>
                  <span className="text-[32px] font-extrabold font-nationalBold">Features</span>
                </div>
                <div>
                  <div className="flex justify-end">
                    <a href="/" className="bg-white rounded-full w-[40px] h-[40px] text-center">
                      <span className="text-3xl text-black align-super">&times;</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="flex justify-center items-center h-screen">
                <img src={require("../assets/images/feature_2.png")} alt="feature 2"
                  className="px-6" />
              </div>
              <div className="md:py-10 py-8 md:px-6 px-4 bg-black block fixed bottom-0 text-left w-screen md:h-[300px] h-[350px]">
                <span className="md:text-[56px] text-[32px] text-national_2bold font-extrabold leading-[108%] text-white">
                  Live event tracking
                </span>
                <div className="md:mt-6 mt-4 mb-10">
                  <span className="text-base font-inter font-normal leading-[140%] text-white">
                    The Map could include live updates on events happening in the user's area, such as concerts, festivals, or other public gatherings. Users could opt-in to receive notifications for events that match their interests, and the Map could display real-time updates on the event's location, crowd size, and other details.
                  </span>
                </div>
              </div>
              <div className="fixed bottom-[40px] gap-[11px] px-6 flex w-screen">
                <div className="h-[2px] bg-white w-1/3 rounded"></div>
                <div className="h-[2px] bg-white w-1/3 rounded"></div>
                <div className="h-[2px] bg-white w-1/3 rounded opacity-30"></div>
              </div>
            </div>
          </div>
          <div>
            <div className={`w-full bg-[#E7EFFB] h-screen`}>
              <div className="flex justify-between items-center py-8 px-6 fixed w-screen z-10">
                <div>
                  <img src={require("../assets/images/black_logo.png")} alt="logo" width={50} height={50}
                    className="w-[50px] h-[50px]" />
                </div>
                <div>
                  <span className="text-[32px] font-extrabold font-nationalBold">Features</span>
                </div>
                <div>
                  <div className="flex justify-end">
                    <a href="/" className="bg-white rounded-full w-[40px] h-[40px] text-center">
                      <span className="text-3xl text-black align-super">&times;</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="flex justify-center items-center h-screen">
                <img src={require("../assets/images/features_mobile_3.png")} alt="feature 3" />
              </div>
              <div className="md:py-10 py-8 md:px-6 px-4 bg-black block fixed bottom-0 text-left w-screen md:h-[300px] h-[350px]">
                <span className="md:text-[56px] text-[32px] text-national_2bold font-extrabold leading-[108%] text-white">
                  Customizable avatars
                </span>
                <div className="md:mt-6 mt-4 mb-10">
                  <span className="text-base font-inter font-normal leading-[140%] text-white">
                    Avatar on the Map, users could have more customization options for their avatars, such as adding accessories, clothing, and other personal touches. This would make it easier for users to identify their friends on the Map and create a more personalized experience.
                  </span>
                </div>
              </div>
              <div className="fixed bottom-[40px] gap-[11px] px-6 flex w-screen">
                <div className="h-[2px] bg-white w-1/3 rounded"></div>
                <div className="h-[2px] bg-white w-1/3 rounded"></div>
                <div className="h-[2px] bg-white w-1/3 rounded"></div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </div> 
  )                                                                                                    
}

export default Features;