import { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';

gsap.registerPlugin(MotionPathPlugin);

const FaqSidebar = () => {

  const pathRef = useRef(null);
  const scooterRef = useRef(null);
  const asteriskRef = useRef(null);
  const frontWheelRef = useRef(null);
  const backWheelRef = useRef(null);

  useEffect(() => {
    const path = pathRef.current;
    const scooter = scooterRef.current;
    const asterisk = asteriskRef.current;
    const frontWheel = frontWheelRef.current;
    const backWheel = backWheelRef.current;

    gsap.to(scooter, {
      duration: 2,
      ease: 'power1.inOut',
      motionPath: {
        path: path,
        align: path,
        autoRotate: true,
        alignOrigin: [0, 0.6],
        toggle: true,
      },
    });
    gsap.to(
      frontWheel,
      {
        rotate: 360,
        duration: 0.6,
        transformOrigin: 'center center',
        repeat: 1,
      },
      '<'
    );
    gsap.to(
      backWheel,
      {
        rotate: 360,
        duration: 0.6,
        transformOrigin: 'center center',
        repeat: 1,
      },
      '<'
    );
    gsap.to(
      asterisk,
      {
        rotate: 360,
        transformOrigin: 'center center',
        autoAlpha: 0,
      },
      '-=0.4'
    );
  }, []);

  return (
    <section className="left-sidebar sticky">
      <section className="left-sidebar-illustration">
        <svg
          className="illustration mx-auto"
          width="200"
          height="302"
          viewBox="0 0 200 202"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="scooter-svg">
            <path
              id="scooterBg"
              d="M155.686 67.4572C155.686 40.9949 134.234 19.5429 107.771 19.5429C81.309 19.5429 59.857 40.9949 59.857 67.4572C59.857 93.92 81.309 115.372 107.771 115.372C134.234 115.372 155.686 93.92 155.686 67.4572Z"
              fill="white"
            />
            <path
              id="road-path"
              d="M127.5 69.5C146.5 85 160.5 139 125 170"
              stroke="black"
              strokeOpacity="0.01"
              ref={pathRef}
            />
            <path
              id="strokes"
              d="M72.631 188.607C86.074 189.952 111.614 186.591 131.106 162.395L132.608 160.414C172.294 108.087 124.553 34.8439 60.66 50.0318C33.5641 56.2862 5.0001 80.244 5 115.258C4.9999 128.363 6.9725 148.83 19.7428 154.879C32.5132 160.929 25.2714 115.258 51.9928 122.629L57.9208 106.873C59.635 102.318 63.486 98.902 68.214 97.744L79.518 94.977C83.995 93.881 88.672 95.76 91.147 99.649L96.964 108.791C98.814 111.698 101.937 113.552 105.376 113.785C113.945 114.364 114.526 126.742 106.049 128.122L88.85 130.922L84.427 145.665C83.759 147.893 81.806 149.493 79.49 149.71C69.206 150.674 69.101 165.65 79.371 166.757L88.85 167.779"
              stroke="url(#paint0_linear_15_3937)"
              strokeWidth="9.05579"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <g id="asterisk" ref={asteriskRef}>
              <path
                id="Vector_3"
                d="M56.6348 173.981L38.6826 193.552"
                stroke="black"
                strokeWidth="8.75393"
                strokeLinecap="square"
                strokeLinejoin="round"
              />
              <path
                id="Vector_4"
                d="M43.9043 171.314L51.4096 196.218"
                stroke="black"
                strokeWidth="8.75393"
                strokeLinecap="square"
                strokeLinejoin="round"
              />
              <path
                id="Vector_5"
                d="M34.9282 181.1L60.386 186.432"
                stroke="black"
                strokeWidth="8.75393"
                strokeLinecap="square"
                strokeLinejoin="round"
              />
            </g>
            <g id="scooter" ref={scooterRef} className="z-100">
              <g id="front-wheel" ref={frontWheelRef}>
                <path
                  id="path100"
                  d="M141.504 56.3321C138.71 55.0264 135.346 56.2976 134.006 59.1655C132.666 62.0335 133.85 65.4293 136.645 66.735C139.44 68.0406 142.804 66.7693 144.143 63.9013C145.483 61.0333 144.299 57.6377 141.504 56.3321ZM135.541 69.0969C131.553 67.2336 129.893 62.325 131.841 58.1543C133.79 53.9835 138.619 52.1062 142.608 53.9696C146.596 55.8327 148.256 60.7416 146.308 64.9124C144.359 69.0832 139.53 70.96 135.541 69.0969Z"
                  fill="#041F37"
                  fillOpacity="0.9"
                />
                <path
                  id="path102"
                  d="M134.911 59.2876C134.911 59.2876 135.347 60.3058 136.453 60.7778C137.559 61.2494 136.843 62.2943 136.843 62.2943C136.843 62.2943 134.753 62.155 134.176 61.5692C133.6 60.9831 134.911 59.2876 134.911 59.2876Z"
                  fill="#041F37"
                />
                <path
                  id="path104"
                  d="M141.17 57.0701C138.934 56.0256 136.188 57.1608 135.048 59.6009C133.908 62.041 134.8 64.8753 137.036 65.9198C139.271 66.9641 142.018 65.8292 143.158 63.3891C144.297 60.949 143.406 58.1144 141.17 57.0701ZM136.555 66.949C133.8 65.6617 132.701 62.1676 134.105 59.1603C135.51 56.1533 138.895 54.7543 141.651 56.0416C144.406 57.3288 145.505 60.8224 144.1 63.8294C142.695 66.8367 139.311 68.2363 136.555 66.949Z"
                  fill="black"
                  fillOpacity="0.33"
                />
                <path
                  id="path106"
                  d="M142.453 62.2228C143.272 60.4693 142.633 58.4388 141.026 57.6882C139.42 56.9376 137.453 57.7508 136.633 59.5043C135.814 61.2583 136.453 63.2882 138.06 64.0388C139.666 64.7894 141.633 63.9768 142.453 62.2228Z"
                  fill="black"
                  fillOpacity="0.47"
                />
                <path
                  id="path108"
                  d="M139.935 62.5533C140.139 62.3976 140.313 62.189 140.431 61.9349C140.468 61.8577 140.495 61.7792 140.519 61.7001C141.035 61.9614 142.163 62.4185 143.625 62.3248L143.984 60.4731C143.984 60.4731 141.419 59.9851 140.425 60.5343C140.351 60.3874 140.252 60.2555 140.131 60.144C140.467 59.6816 141.222 58.4955 141.476 56.8584L139.824 56.3122C139.824 56.3122 138.923 58.6181 139.043 59.8107C138.922 59.8277 138.803 59.8589 138.688 59.9064C138.186 58.8669 136.208 57.6345 136.208 57.6345L135.181 59.2257C136.133 60.2298 137.214 60.668 137.797 60.8439C137.663 61.195 137.655 61.5637 137.754 61.8893L137.724 61.8833C136.6 61.72 134.086 63.3927 134.086 63.3927L134.943 65.0925C137.01 64.3276 138.158 62.78 138.158 62.78C138.158 62.78 138.218 62.7155 138.274 62.6205C138.338 62.6662 138.405 62.7077 138.479 62.742C138.642 62.8185 138.815 62.8568 138.987 62.8635L138.984 62.9051C138.984 62.9051 138.745 64.8064 139.717 66.7584L141.525 66.0995C141.525 66.0995 140.861 63.2066 139.935 62.5533Z"
                  fill="#041F37"
                />
                <path
                  id="path110"
                  d="M138.721 62.2674C138.242 62.0434 138.052 61.4378 138.296 60.9145C138.541 60.3915 139.127 60.1492 139.606 60.3731C140.086 60.597 140.276 61.2021 140.032 61.7251C139.787 62.2485 139.201 62.4913 138.721 62.2674Z"
                  fill="#666666"
                />
              </g>
              <g id="back-wheel" ref={backWheelRef}>
                <path
                  id="path112"
                  d="M91.5363 39.3255C90.1965 42.1935 91.38 45.5892 94.175 46.895C96.9699 48.2006 100.334 46.9292 101.673 44.0612C103.013 41.1932 101.83 37.7977 99.0348 36.492C96.2398 35.1863 92.8761 36.4575 91.5363 39.3255ZM89.3717 38.3143C91.3201 34.1435 96.1498 32.2662 100.138 34.1296C104.127 35.9928 105.787 40.9016 103.838 45.0724C101.89 49.2432 97.0601 51.1201 93.0716 49.2569C89.083 47.3935 87.4233 42.4851 89.3717 38.3143Z"
                  fill="#041F37"
                  fillOpacity="0.9"
                />
                <path
                  id="path116"
                  d="M92.5618 39.7529C91.4219 42.193 92.3135 45.0274 94.549 46.0717C96.785 47.1162 99.531 45.9812 100.671 43.5411C101.811 41.101 100.919 38.2665 98.6833 37.222C96.4477 36.1777 93.7017 37.3128 92.5618 39.7529ZM91.6189 39.3124C93.0237 36.3054 96.4084 34.9062 99.1638 36.1934C101.92 37.4808 103.019 40.9745 101.614 43.9816C100.209 46.9888 96.824 48.3883 94.0683 47.1009C91.3129 45.8137 90.2141 42.3196 91.6189 39.3124Z"
                  fill="black"
                  fillOpacity="0.33"
                />
                <path
                  id="path118"
                  d="M93.9085 39.545C94.7277 37.7915 96.6946 36.9782 98.3016 37.7289C99.9084 38.4796 100.547 40.51 99.7277 42.2635C98.9083 44.0175 96.9416 44.8302 95.3349 44.0796C93.7279 43.3288 93.0891 41.299 93.9085 39.545Z"
                  fill="#808080"
                />
                <path
                  id="path120"
                  d="M95.2706 41.6878C95.259 41.4315 95.3078 41.1645 95.4264 40.9104C95.4625 40.8332 95.5048 40.7616 95.5503 40.6927C95.0188 40.4646 93.944 39.8926 93.078 38.7115L94.2676 37.2474C94.2676 37.2474 96.288 38.9019 96.5044 40.0164C96.6649 39.9791 96.8294 39.9703 96.9925 39.9916C97.1316 39.4371 97.5573 38.0973 98.6493 36.8513L100.128 37.7677C100.128 37.7677 98.9376 39.9385 97.9464 40.612C98.0111 40.7158 98.0637 40.8273 98.1009 40.9457C99.2202 40.6636 101.435 41.39 101.435 41.39L100.873 43.1981C99.4927 43.1131 98.4627 42.5652 97.9537 42.2309C97.77 42.5585 97.4927 42.8015 97.1792 42.9342L97.2037 42.9541C98.0499 43.7111 98.3808 46.7129 98.3808 46.7129L96.5264 47.1457C95.787 45.0701 96.237 43.1963 96.237 43.1963C96.237 43.1963 96.2481 43.1089 96.2849 43.005C96.2089 42.9852 96.1337 42.9602 96.0602 42.9258C95.8968 42.8495 95.757 42.7421 95.6413 42.6142L95.6108 42.6428C95.6108 42.6428 94.3064 44.0468 92.185 44.5532L91.5307 42.7444C91.5307 42.7444 94.1748 41.3965 95.2706 41.6878Z"
                  fill="#041F37"
                />
              </g>
              <g id="turbo">
                <path
                  id="path128"
                  d="M112.068 43.9344L99.273 39.1105L101.691 48.0444C101.691 48.0444 109.682 51.4382 110.963 51.3579C112.244 51.2781 112.068 43.9344 112.068 43.9344Z"
                  fill="#041F37"
                  fillOpacity="0.9"
                />
                <path
                  id="path130"
                  d="M104.117 48.9741C104.117 48.9741 100.283 47.9971 99.5429 47.584C98.8034 47.1706 92.9853 40.8558 92.7591 40.2749C92.533 39.6941 93.5539 36.7099 93.5539 36.7099L96.9609 36.6053L104.051 44.0571L104.117 48.9741Z"
                  fill="#A7A7A7"
                />
                <path
                  id="path132"
                  d="M110.297 46.2285C111.044 44.6286 110.462 42.7768 108.996 42.0921C107.53 41.4072 105.736 42.1486 104.988 43.7485C104.241 45.3482 104.823 47.2005 106.289 47.8854C107.755 48.5702 109.55 47.8282 110.297 46.2285Z"
                  fill="#808080"
                />
                <path
                  id="path134"
                  d="M106.784 46.8267C105.839 46.3855 105.464 45.1923 105.946 44.1616C106.427 43.131 107.583 42.6529 108.528 43.0941C109.472 43.5353 109.847 44.7287 109.366 45.7593C108.884 46.79 107.728 47.2679 106.784 46.8267ZM107.805 44.3853C107.495 44.2405 107.115 44.3979 106.957 44.7362C106.799 45.0746 106.922 45.4663 107.232 45.6112C107.543 45.7561 107.922 45.5992 108.08 45.2609C108.238 44.9225 108.115 44.5302 107.805 44.3853Z"
                  fill="#3C3C3C"
                />
                <path
                  id="path136"
                  d="M107.232 45.6111C106.922 45.4663 106.799 45.0746 106.957 44.7362C107.115 44.3979 107.495 44.2405 107.805 44.3853C108.115 44.5302 108.238 44.9225 108.08 45.2609C107.922 45.5992 107.543 45.7561 107.232 45.6111Z"
                  fill="#909090"
                />
                <path
                  id="path162"
                  d="M97.7697 38.6256L101.834 42.7872C101.95 42.9063 102.039 43.0526 102.095 43.2156L102.949 45.7046C103.001 45.8585 102.879 46.028 102.722 46.0185L100.09 45.8625C99.0049 45.798 98.0078 45.3075 97.2924 44.4853L93.5839 40.2274C93.4123 40.0298 93.4967 39.6974 93.7456 39.5919L96.5569 38.3984C96.9844 38.2167 97.4567 38.3049 97.7697 38.6256Z"
                  fill="#E6E6E6"
                />
                <path
                  id="path164"
                  d="M101.045 43.4805L100.988 43.4424L98.271 40.8328C98.1803 40.7457 98.1786 40.5929 98.267 40.4911C98.339 40.4084 98.4477 40.3853 98.5352 40.4261C98.5552 40.4355 98.5741 40.4482 98.5911 40.4643L101.309 43.0734C101.399 43.1605 101.401 43.3135 101.312 43.4153C101.241 43.4978 101.131 43.5211 101.045 43.4805Z"
                  fill="black"
                  fillOpacity="0.33"
                />
                <path
                  id="path166"
                  d="M99.8154 43.4497L99.7593 43.4109L97.0416 40.8019C96.9509 40.7148 96.9495 40.5617 97.038 40.46C97.1099 40.3769 97.2188 40.3539 97.306 40.3946C97.3262 40.404 97.3451 40.4168 97.3621 40.4329L100.079 43.0424C100.17 43.1294 100.172 43.2821 100.083 43.3838C100.012 43.4666 99.9022 43.4903 99.8154 43.4497Z"
                  fill="black"
                  fillOpacity="0.33"
                />
                <path
                  id="path168"
                  d="M98.7905 43.5136L98.7342 43.4754L96.0167 40.8658C95.9263 40.7788 95.9246 40.626 96.0131 40.5238C96.0848 40.4413 96.1939 40.418 96.2811 40.4587C96.3011 40.4681 96.3203 40.4807 96.3373 40.4968L99.0548 43.1065C99.1452 43.1935 99.1471 43.346 99.0584 43.4484C98.9867 43.5309 98.8774 43.5542 98.7905 43.5136Z"
                  fill="black"
                  fillOpacity="0.33"
                />
              </g>
              <g id="head">
                <path
                  id="path122"
                  d="M144.26 24.5429L142.985 30.6433C142.985 30.6433 142.404 30.7336 140.876 29.568C140.876 29.568 140.518 29.5359 140.663 30.7346C140.663 30.7346 139.332 32.8732 137.108 31.2458L136.696 26.8009C136.696 26.8009 141.037 23.9878 141.413 23.8919C141.79 23.796 144.26 24.5429 144.26 24.5429Z"
                  fill="#CE7B02"
                />
                <path
                  id="path124"
                  d="M144.529 25.121C144.529 25.121 145.1 26.3832 144.903 27.6934C144.705 29.0035 143.467 30.1448 143.467 30.1448L144.529 25.121Z"
                  fill="#F4CC81"
                />
                <path
                  id="path126"
                  d="M144.218 24.5003L144.753 24.8186L143.585 30.3807L142.994 30.6246L144.218 24.5003Z"
                  fill="#999999"
                />
                <path
                  id="path144"
                  d="M140.617 20.715L140.095 25.861C140.078 26.0265 139.942 26.1574 139.785 26.1574C139.621 26.1576 139.498 26.0131 139.514 25.8386L140.004 20.6093L140.617 20.715Z"
                  fill="#ADADAD"
                />
                <path
                  id="path156"
                  d="M141.131 20.5026C140.684 21.3784 139.213 21.3253 139.213 21.3253C139.213 21.3253 140.505 17.4052 141.501 16.6942C141.501 16.6942 142.101 18.6027 141.131 20.5026Z"
                  fill="#CCCCCC"
                />
                <path
                  id="path172"
                  d="M138.934 27.7782C139.265 27.0704 139.007 26.2517 138.359 25.9487C137.711 25.646 136.917 25.9736 136.586 26.6815C136.256 27.3893 136.514 28.2082 137.162 28.511C137.811 28.8139 138.604 28.4861 138.934 27.7782Z"
                  fill="#ADADAD"
                />
                <path
                  id="path174"
                  d="M138.45 27.4841C138.651 27.0536 138.494 26.5548 138.1 26.3703C137.705 26.1861 137.222 26.3858 137.021 26.8163C136.819 27.2473 136.976 27.7461 137.371 27.9303C137.766 28.1148 138.249 27.9151 138.45 27.4841Z"
                  fill="#4D4D4D"
                />
                <path
                  id="path176"
                  d="M141.147 25.3507L141.325 24.3478L144.11 25.0395L143.842 26.3798L141.147 25.3507Z"
                  fill="#DDA450"
                />
                <path
                  id="path178"
                  d="M143.842 26.3798L144.11 25.0395L144.631 25.1686L144.33 26.5662L143.842 26.3798Z"
                  fill="#B9B9B9"
                />
              </g>
              <path
                id="path182"
                d="M122.364 37.7999C122.362 37.7971 122.36 37.795 122.358 37.7922C122.36 37.795 122.362 37.7977 122.364 37.7999ZM105.449 24.8256C106.135 25.08 106.81 25.3644 107.474 25.6744C106.811 25.3647 106.135 25.0798 105.449 24.8256Z"
                fill="#D0D0D0"
              />
              <g id="body">
                <path
                  id="path138"
                  d="M141.067 47.6181C147.056 50.4161 147.554 56.9827 147.554 56.9827C147.554 56.9827 142.32 53.6325 139.655 53.8352C136.99 54.0381 135.162 56.5323 131.758 54.9425L141.067 47.6181Z"
                  fill="#CE7B02"
                />
                <path
                  id="path140"
                  d="M102.703 25.5114L99.2032 24.351L95.5297 25.825C95.5297 25.825 94.4217 26.7325 96.1572 27.5433C97.8927 28.354 102.703 25.5114 102.703 25.5114Z"
                  fill="#F24738"
                />
                <path
                  id="path142"
                  d="M103.292 25.5376L101.554 24.9975L95.3903 27.366L96.2391 28.0335L103.292 25.5376Z"
                  fill="#CCCCCC"
                />
                <path
                  id="path146"
                  d="M122.358 37.7922C122.358 37.7922 119.874 38.9393 119.258 41.411C118.58 44.1353 118.249 46.3237 124.442 49.2171C129.921 51.7766 133.111 47.4309 134.777 43.8654C136.442 40.3005 136.476 31.1771 136.476 31.1771L141.192 30.6655C141.192 30.6655 144.414 41.0758 143.595 47.6414C143.537 48.1095 143.113 48.4512 142.684 48.366C141.958 48.2223 140.89 48.0965 140.275 48.4245C139.281 48.9554 129.557 58.7497 125.804 56.6845C117.097 51.8937 113.913 53.0304 112.28 52.2673C110.646 51.5042 110.497 44.0141 110.497 44.0141C110.497 44.0141 94.3167 31.7053 93.4992 29.1066C93.4992 29.1066 95.9401 26.8986 105.449 24.8256C105.449 24.8256 118.762 30.0496 122.358 37.7922Z"
                  fill="#FFBC40"
                />
                <path
                  id="path150"
                  d="M110.75 47.6292C110.697 47.1776 110.656 46.7358 110.623 46.3233C111.148 46.3915 111.673 46.4462 112.183 46.3713C113.545 46.1704 116.404 44.7042 118.25 39.2212C118.693 37.9042 119.706 36.8254 120.744 35.8955C121.437 36.6591 121.966 37.2933 122.305 37.7245C122.307 37.7265 122.308 37.7283 122.31 37.7304C122.321 37.7452 122.333 37.7594 122.344 37.7734C122.344 37.7743 122.345 37.7752 122.346 37.7762C122.35 37.7819 122.354 37.787 122.358 37.7922C122.358 37.7922 122.357 37.7929 122.355 37.7943C122.265 37.8363 120.418 38.7249 119.556 40.5715C119.436 40.8303 119.334 41.1076 119.26 41.4043C119.259 41.4066 119.259 41.4088 119.258 41.411C118.58 44.1353 118.249 46.3237 124.442 49.2171C129.921 51.7766 133.111 47.4309 134.777 43.8654C136.442 40.3005 136.476 31.1771 136.476 31.1771L139.29 30.8717C139.349 31.8666 139.226 32.707 139.369 33.6818C139.441 35.9893 139.517 37.216 139.883 38.8373C140.298 40.6751 140.369 42.5085 140.253 44.4217C140.139 44.7648 139.998 45.1004 139.823 45.4265L139.736 45.5043C139.023 46.1918 133.912 50.4389 133.122 50.8976C132.044 51.5245 130.818 52.4486 129.673 52.905C128.742 53.2764 127.966 53.4986 126.968 53.4054C121.428 51.8515 116.1 49.7017 110.75 47.6292Z"
                  fill="#D09A35"
                />
                <path
                  id="path152"
                  d="M110.727 22.224C112.837 23.2097 117.211 27.3347 120.206 28.7336C123.201 30.1326 126.748 30.6169 126.709 35.9342C126.698 37.377 124.016 39.9242 122.358 37.7922C120.701 35.6608 113.86 27.9407 105.449 24.8256C105.449 24.8256 103.776 21.1422 105.824 20.2959C107.005 19.8074 110.727 22.224 110.727 22.224Z"
                  fill="#041F37"
                  fillOpacity="0.9"
                />
                <path
                  id="path154"
                  d="M109.015 41.5326C105.096 39.7019 94.0449 29.1806 94.0449 29.1806C94.0449 29.1806 100.283 26.1223 110.424 30.8598C115.12 33.0536 113.951 40.1894 110.138 41.7659C109.909 41.8604 109.521 41.7689 109.015 41.5326Z"
                  fill="#FFCF77"
                />
                <path
                  id="path158"
                  d="M96.7821 28.4687C96.7821 28.4687 100.2 28.0742 102.144 28.3491C104.089 28.6245 102.431 26.4925 101.474 26.5888C100.517 26.6845 96.1711 28.0018 96.7821 28.4687Z"
                  fill="#E6E6E6"
                />
                <path
                  id="path160"
                  d="M139.854 36.8345C139.594 34.65 139.25 31.7547 139.386 30.8464C139.253 30.8602 139.121 30.874 138.989 30.8885L138.932 30.8943C138.818 31.8154 139.01 33.6666 139.398 36.9285C139.781 40.1404 140.303 44.5416 139.734 45.1734C138.431 46.6194 130.285 53.4462 127.493 53.248C124.883 53.0626 113.133 48.0583 110.701 47.0121C110.692 47.0919 110.68 47.1711 110.663 47.2506C110.681 47.3743 110.697 47.4963 110.704 47.6206C113.603 48.8702 124.783 53.5725 127.419 53.7592C130.547 53.9814 138.846 46.8363 140.058 45.4909C140.738 44.7369 140.419 41.5736 139.854 36.8345Z"
                  fill="#E6E6E6"
                />
                <path
                  id="path170"
                  d="M141.309 44.2109C141.215 44.1327 141.157 44.014 141.151 43.8843L140.901 38.9037C141.05 38.3866 141.371 38.7653 141.383 38.8221L142.162 44.451C142.2 44.6341 141.993 44.7794 141.856 44.6657L141.309 44.2109Z"
                  fill="#E6E6E6"
                />
                <path
                  id="path180"
                  d="M146.541 55.4235C144.076 51.648 140.333 49.8992 140.439 49.3156C140.546 48.732 141.706 48.9119 141.706 48.9119C141.747 48.9304 141.788 48.949 141.828 48.9678C145.706 50.7793 146.541 55.4235 146.541 55.4235Z"
                  fill="#DDA450"
                />
                <path
                  id="path184"
                  d="M123.146 38.4483C122.871 38.32 122.607 38.1099 122.364 37.7999C122.362 37.7977 122.36 37.795 122.358 37.7922C122.354 37.7871 122.35 37.7819 122.346 37.7762C122.345 37.7752 122.344 37.7743 122.344 37.7734C122.333 37.7594 122.321 37.7452 122.31 37.7304C122.308 37.7283 122.307 37.7265 122.305 37.7245C120.703 35.6851 114.859 29.1251 107.476 25.6757C107.475 25.6752 107.475 25.675 107.474 25.6745L107.473 25.6744C106.81 25.3644 106.135 25.08 105.449 24.8256C105.449 24.8256 105.449 24.8256 105.449 24.8255C105.449 24.8255 105.449 24.8255 105.448 24.8254C105.448 24.8253 105.448 24.8253 105.448 24.8248C105.448 24.8247 105.448 24.8247 105.448 24.8247C105.437 24.7991 105.172 24.2077 104.986 23.4532C105.111 23.5542 105.245 23.6469 105.387 23.7277C105.506 23.7959 105.627 23.8581 105.751 23.9158C106.661 24.3412 107.684 24.5158 108.611 24.9023C108.919 25.0302 109.226 25.1662 109.533 25.3097C111.196 26.0867 112.836 27.0791 114.262 28.1959C117.242 30.5304 119.46 33.8096 122.468 36.0873C122.929 36.4363 123.439 36.7786 123.983 37.0327C124.627 37.3336 125.319 37.5106 126.032 37.4292C125.32 38.2783 124.163 38.9234 123.146 38.4483Z"
                  fill="#3F3F3F"
                />
              </g>
            </g>
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_15_3937"
              x1="-36.2528"
              y1="166.152"
              x2="146.63"
              y2="37.2256"
              gradientUnits="userSpaceOnUse"
            >
              <stop />
              <stop offset="0.477976" stopColor="#041F37" />
              <stop offset="1" stopColor="#F0E8DB" />
            </linearGradient>
          </defs>
        </svg>
      </section>
    </section>
  )
};
export default FaqSidebar;