import PrivacySidebar from "../components/Privacy_sidebar";

function Privacy() {
  return (
    <div>
      <div className="lg:flex hidden">
        <div className="w-2/5 bg-[#E7EFFB] px-9 pt-8 pb-10 flex flex-col justify-center h-[100vh]">
          <PrivacySidebar />
        </div>
        <div className="w-3/5 bg-white px-16 pr-9 pt-8 pb-10 h-[100vh] flex flex-col justify-between overflow-auto">
          <div className="block">
            <div className="flex justify-end fixed right-[3.3rem]">
              <a href="/" className="bg-[#E5E5E5] rounded-full w-[40px] h-[40px] text-center">
                <span className="text-3xl text-black align-super">&times;</span>
              </a>
            </div>
            <div className="mt-[122px]">
              <a href="/">
                <img src={require("../assets/images/green_logo.png")} alt="Green logo" width={50} height={50} />
              </a>
              <div className="mt-6">
                <span className="text-6xl font-extrabold leading-[108%] font-nationalBold">Privacy</span>
              </div>
              <div className="mt-3">
                <span className="text-[#9C9D9F] font-bold text-base font-national">Updated May 31,2023</span>
              </div>
              <div className="pt-10">
                <div className="max-w-xl mt-8">
                  <span className="text-[#252525] text-base font-inter font-bold leading-[125%]">Introduction</span>
                  <div className="mt-4 text-[#252525] text-base font-inter leading-[140%]">
                    Welcome to Linq, an application designed to connect users with local events and experiences via a gamified platform. This Privacy Policy describes how we collect, use, disclose, store and protect your personal information.
                  </div>
                </div>
                <div className="max-w-xl mt-8">
                  <span className="text-[#252525] text-base font-inter font-bold leading-[125%]">Information Collection</span>
                  <div className="mt-4 text-[#252525] text-base font-inter leading-[140%]">
                    We collect information to provide better services to all our users. The type of information we collect can be broadly classified into:
                  </div>
                </div>
                <div className="max-w-xl mt-8">
                  <span className="text-[#252525] text-base font-inter font-bold leading-[125%]">Information You Provide Us Directly</span>
                  <div className="mt-4 text-[#252525] text-base font-inter leading-[140%]">
                    Your username, password, email address when you register for a Linq account, and profile information that you provide for your user profile. This might include your name, picture, and contact information. Your profile information will be publicly displayed on our platform.
                  </div>
                </div>
                <div className="max-w-xl mt-8">
                  <span className="text-[#252525] text-base font-inter font-bold leading-[125%]">Information We Get From Your Use of Our Services</span>
                  <div className="mt-4 text-[#252525] text-base font-inter leading-[140%]">
                    We collect information about the services you use and how you use them. This information includes:
                  </div>
                </div>
                <div className="max-w-xl mt-8">
                  <section className="mt-[55px] font-inter text-base fon-light leading-[22px] text-left">
                    <ul className="ml-6 list-disc">
                      <li className="pt-2">
                        <span className="font-nationalBold">Device Information:</span> We may
                        collect device-specific information (such as your hardware
                        model, operating system version, unique device identifiers,
                        and mobile network information including phone number).
                      </li>
                      <li className="pt-2">
                        <span className="font-nationalBold">Log Information:</span> When you
                        use our services or view content provided by Linq, we
                        automatically collect and store certain information in
                        server logs. This includes your search queries, internet
                        protocol address, device event information such as crashes,
                        system activity, hardware settings, browser type, browser
                        language, the date and time of your request, and referral
                        URL.
                      </li>
                      <li className="pt-2">
                        <span className="font-nationalBold">Location Information:</span> When
                        you use Linq, we may collect and process information about
                        your actual location. We use various technologies to
                        determine location, including IP address, GPS, and other
                        sensors that may provide us with information on nearby
                        devices, Wi-Fi access points, and cell towers.
                      </li>
                    </ul>
                  </section>
                </div>
                <div className="max-w-xl mt-8">
                  <span className="text-[#252525] text-base font-inter font-bold leading-[125%]">Use of Information</span>
                  <div className="mt-4 text-[#252525] text-base font-inter leading-[140%]">
                    We use the information we collect from all our services for the
                    following purposes:
                  </div>
                </div>
                <div className="max-w-xl mt-8">
                  <section className="mt-[55px] font-inter text-base fon-light leading-[22px] text-left">
                    <ul className="ml-6 list-disc">
                      <li className="pt-2">
                        <span className="font-nationalBold">Provide our Services: </span> We use your information to deliver our services, like processing the terms you search for in order to display local events, or suggesting users you may know.
                      </li>
                      <li className="pt-2">
                        <span className="font-nationalBold">Maintain & Improve our Services:</span>
                        We also use your information to ensure our services are
                        working as intended, such as tracking outages or
                        troubleshooting issues that you report to us.
                      </li>
                      <li className="pt-2">
                        <span className="font-nationalBold">Develop New Services:</span>
                        We use
                        the information we collect in existing services to help us
                        develop new ones.
                      </li>
                      <li className="pt-2">
                        <span className="font-nationalBold">Provide Personalized Services:</span>
                        We use the information we collect to customize our services
                        for you, including providing recommendations, personalized
                        content,
                        <span className="font-nationalBold">and customized search results.</span>
                      </li>
                      <li className="pt-2">
                        <span className="font-nationalBold">Measure Performance:</span>
                        We use
                        data for analytics and measurement to understand how our
                        services are used.
                      </li>
                    </ul>
                  </section>
                </div>
                <div className="max-w-xl mt-8">
                  <span className="text-[#252525] text-base font-inter font-bold leading-[125%]">Information We Share</span>
                  <div className="mt-4 text-[#252525] text-base font-inter leading-[140%]">
                    We do not share personal information with companies, organizations and individuals outside of Linq unless one of the following circumstances applies:
                  </div>
                </div>
                <div className="max-w-xl mt-8">
                  <section className="mt-[55px] font-inter text-base fon-light leading-[22px] text-left">
                    <ul className="ml-6 list-disc">
                      <li className="pt-2">
                        <span className="font-nationalBold">With Your Consent:</span>
                        We will share personal information with companies, organizations, or individuals outside of Linq when we have your consent to do so
                      </li>
                      <li className="pt-2">
                        <span className="font-nationalBold">For Legal Reasons:</span>
                        We will share personal information with companies, organizations or individuals outside of Linq if we have a good-faith belief that access, use, preservation, or disclosure of the information is reasonably necessary to meet any applicable law, regulation, legal process or enforceable governmental request.
                      </li>
                    </ul>
                  </section>
                </div>
                <div className="max-w-xl mt-8">
                  <span className="text-[#252525] text-base font-inter font-bold leading-[125%]">Information Security</span>
                  <div className="mt-4 text-[#252525] text-base font-inter leading-[140%]">
                    We work hard to protect Linq and our users from unauthorized access to or unauthorized alteration, disclosure, or destruction of information we hold. In particular:
                  </div>
                </div>
                <div className="max-w-xl mt-8">
                  <section className="mt-[55px] font-inter text-base fon-light leading-[22px] text-left">
                    <ul className="ml-6 list-disc">
                      <li className="pt-2">
                        We encrypt many of our services using SSL.
                      </li>
                      <li className="pt-2">
                        We review our information collection, storage and processing practices, including physical security measures, to guard against unauthorized access to systems.
                      </li>
                      <li className="pt-2">
                        We restrict access to personal information to Linq employees, contractors and agents who need to know that information in order to process it for us, and who are subject to strict contractual confidentiality obligations and may be disciplined or terminated if they fail to meet these obligations.
                      </li>
                    </ul>
                  </section>
                </div>
                <div className="max-w-xl mt-8">
                  <span className="text-[#252525] text-base font-inter font-bold leading-[125%]">Changes to Our Privacy Policy</span>
                  <div className="mt-4 text-[#252525] text-base font-inter leading-[140%]">
                    Our Privacy Policy may change from time to time. We will post any privacy policy changes on this page and, if the changes are significant, we will provide a more prominent notice (including, for certain services, email notification of privacy policy changes).
                  </div>
                </div>
                <div className="max-w-xl mt-8">
                  <span className="text-[#252525] text-base font-inter font-bold leading-[125%]">How to Contact Us</span>
                  <div className="mt-4 text-[#252525] text-base font-inter leading-[140%]">
                    If you have any questions about this Privacy Policy, please feel <a href="mailto: support@letslinq.co" className="text-[#3872e0]">privacy@letslinq.co</a>
                  </div>
                  <div className="my-4 text-[#252525] text-base font-inter leading-[140%]">By using Linq, you consent to our Privacy Policy. If you don't agree, please do not use our services.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="md:flex lg:hidden sm:flex">
        <section id="example-2" className="h-[100vh] bg-white pb-10 w-full">
          <div className="flex justify-between items-center py-8 px-6 fixed w-full z-10 bg-gradient-to-b from-white via-white to-transparent">
            <a href="/">
              <img src={require("../assets/images/green_logo.png")} alt="logo" width={50} height={50} />
            </a>
            <div>
              <span className="text-[32px] font-extrabold font-nationalBold">Privacy</span>
            </div>
            <div>
              <div className="flex justify-end">
                <a href="/" className="bg-[#E5E5E5] rounded-full w-[40px] h-[40px] text-center">
                  <span className="text-3xl text-black align-super">&times;</span>
                </a>
              </div>
            </div>
          </div>
          <div className="overflow-auto pt-8 px-6">
            <div className="mt-10 pt-8">
              <span className="text-[#9C9D9F] font-bold text-base font-national">Updated May 31,2023</span>
            </div>
            <div className="pt-10">
              <div className="max-w-xl mt-8">
                <span className="text-[#252525] text-base font-inter font-bold leading-[125%]">Introduction</span>
                <div className="mt-4 text-[#252525] text-base font-inter leading-[140%]">
                  Welcome to Linq, an application designed to connect users with local events and experiences via a gamified platform. This Privacy Policy describes how we collect, use, disclose, store and protect your personal information.
                </div>
              </div>
              <div className="max-w-xl mt-8">
                <span className="text-[#252525] text-base font-inter font-bold leading-[125%]">Information Collection</span>
                <div className="mt-4 text-[#252525] text-base font-inter leading-[140%]">
                  We collect information to provide better services to all our users. The type of information we collect can be broadly classified into:
                </div>
              </div>
              <div className="max-w-xl mt-8">
                <span className="text-[#252525] text-base font-inter font-bold leading-[125%]">Information You Provide Us Directly</span>
                <div className="mt-4 text-[#252525] text-base font-inter leading-[140%]">
                  Your username, password, email address when you register for a Linq account, and profile information that you provide for your user profile. This might include your name, picture, and contact information. Your profile information will be publicly displayed on our platform.
                </div>
              </div>
              <div className="max-w-xl mt-8">
                <span className="text-[#252525] text-base font-inter font-bold leading-[125%]">Information We Get From Your Use of Our Services</span>
                <div className="mt-4 text-[#252525] text-base font-inter leading-[140%]">
                  We collect information about the services you use and how you use them. This information includes:
                </div>
              </div>
              <div className="max-w-xl mt-8">
                <section className="mt-[55px] font-inter text-base fon-light leading-[22px] text-left">
                  <ul className="ml-6 list-disc">
                    <li className="pt-2">
                      <span className="font-nationalBold">Device Information:</span> We may
                      collect device-specific information (such as your hardware
                      model, operating system version, unique device identifiers,
                      and mobile network information including phone number).
                    </li>
                    <li className="pt-2">
                      <span className="font-nationalBold">Log Information:</span> When you
                      use our services or view content provided by Linq, we
                      automatically collect and store certain information in
                      server logs. This includes your search queries, internet
                      protocol address, device event information such as crashes,
                      system activity, hardware settings, browser type, browser
                      language, the date and time of your request, and referral
                      URL.
                    </li>
                    <li className="pt-2">
                      <span className="font-nationalBold">Location Information:</span> When
                      you use Linq, we may collect and process information about
                      your actual location. We use various technologies to
                      determine location, including IP address, GPS, and other
                      sensors that may provide us with information on nearby
                      devices, Wi-Fi access points, and cell towers.
                    </li>
                  </ul>
                </section>
              </div>
              <div className="max-w-xl mt-8">
                <span className="text-[#252525] text-base font-inter font-bold leading-[125%]">Use of Information</span>
                <div className="mt-4 text-[#252525] text-base font-inter leading-[140%]">
                  We use the information we collect from all our services for the
                  following purposes:
                </div>
              </div>
              <div className="max-w-xl mt-8">
                <section className="mt-[55px] font-inter text-base fon-light leading-[22px] text-left">
                  <ul className="ml-6 list-disc">
                    <li className="pt-2">
                      <span className="font-nationalBold">Provide our Services: </span> We use your information to deliver our services, like processing the terms you search for in order to display local events, or suggesting users you may know.
                    </li>
                    <li className="pt-2">
                      <span className="font-nationalBold">Maintain & Improve our Services:</span>
                      We also use your information to ensure our services are
                      working as intended, such as tracking outages or
                      troubleshooting issues that you report to us.
                    </li>
                    <li className="pt-2">
                      <span className="font-nationalBold">Develop New Services:</span>
                      We use
                      the information we collect in existing services to help us
                      develop new ones.
                    </li>
                    <li className="pt-2">
                      <span className="font-nationalBold">Provide Personalized Services:</span>
                      We use the information we collect to customize our services
                      for you, including providing recommendations, personalized
                      content,
                      <span className="font-nationalBold">and customized search results.</span>
                    </li>
                    <li className="pt-2">
                      <span className="font-nationalBold">Measure Performance:</span>
                      We use
                      data for analytics and measurement to understand how our
                      services are used.
                    </li>
                  </ul>
                </section>
              </div>
              <div className="max-w-xl mt-8">
                <span className="text-[#252525] text-base font-inter font-bold leading-[125%]">Information We Share</span>
                <div className="mt-4 text-[#252525] text-base font-inter leading-[140%]">
                  We do not share personal information with companies, organizations and individuals outside of Linq unless one of the following circumstances applies:
                </div>
              </div>
              <div className="max-w-xl mt-8">
                <section className="mt-[55px] font-inter text-base fon-light leading-[22px] text-left">
                  <ul className="ml-6 list-disc">
                    <li className="pt-2">
                      <span className="font-nationalBold">With Your Consent:</span>
                      We will share personal information with companies, organizations, or individuals outside of Linq when we have your consent to do so
                    </li>
                    <li className="pt-2">
                      <span className="font-nationalBold">For Legal Reasons:</span>
                      We will share personal information with companies, organizations or individuals outside of Linq if we have a good-faith belief that access, use, preservation, or disclosure of the information is reasonably necessary to meet any applicable law, regulation, legal process or enforceable governmental request.
                    </li>
                  </ul>
                </section>
              </div>
              <div className="max-w-xl mt-8">
                <span className="text-[#252525] text-base font-inter font-bold leading-[125%]">Information Security</span>
                <div className="mt-4 text-[#252525] text-base font-inter leading-[140%]">
                  We work hard to protect Linq and our users from unauthorized access to or unauthorized alteration, disclosure, or destruction of information we hold. In particular:
                </div>
              </div>
              <div className="max-w-xl mt-8">
                <section className="mt-[55px] font-inter text-base fon-light leading-[22px] text-left">
                  <ul className="ml-6 list-disc">
                    <li className="pt-2">
                      We encrypt many of our services using SSL.
                    </li>
                    <li className="pt-2">
                      We review our information collection, storage and processing practices, including physical security measures, to guard against unauthorized access to systems.
                    </li>
                    <li className="pt-2">
                      We restrict access to personal information to Linq employees, contractors and agents who need to know that information in order to process it for us, and who are subject to strict contractual confidentiality obligations and may be disciplined or terminated if they fail to meet these obligations.
                    </li>
                  </ul>
                </section>
              </div>
              <div className="max-w-xl mt-8">
                <span className="text-[#252525] text-base font-inter font-bold leading-[125%]">Changes to Our Privacy Policy</span>
                <div className="mt-4 text-[#252525] text-base font-inter leading-[140%]">
                  Our Privacy Policy may change from time to time. We will post any privacy policy changes on this page and, if the changes are significant, we will provide a more prominent notice (including, for certain services, email notification of privacy policy changes).
                </div>
              </div>
              <div className="max-w-xl mt-8">
                <span className="text-[#252525] text-base font-inter font-bold leading-[125%]">How to Contact Us</span>
                <div className="mt-4 text-[#252525] text-base font-inter leading-[140%]">
                  If you have any questions about this Privacy Policy, please feel <a href="mailto: support@letslinq.co" className="text-[#3872e0]">privacy@letslinq.co</a>
                </div>
                <div className="my-4 text-[#252525] text-base font-inter leading-[140%]">By using Linq, you consent to our Privacy Policy. If you don't agree, please do not use our services.</div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Privacy;