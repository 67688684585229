import PrivacySidebar from "../components/Privacy_sidebar";

function Terms() {
  return (
    <div>
      <div className="lg:flex hidden">
        <div className="w-2/5 bg-[#E7EFFB] px-9 pt-8 pb-10 flex flex-col justify-center h-[100vh]">
          <PrivacySidebar />
        </div>
        <div className="w-3/5 bg-white px-16 pr-9 pt-8 pb-10 h-[100vh] flex flex-col justify-between overflow-auto">
          <div className="block">
            <div className="flex justify-end fixed right-[3.3rem]">
              <a href="/" className="bg-[#E5E5E5] rounded-full w-[40px] h-[40px] text-center">
                <span className="text-3xl text-black align-super">&times;</span>
              </a>
            </div>
            <div className="mt-[122px]">
              <div>
                <img src={require("../assets/images/green_logo.png")} alt="Green logo" width={50} height={50} />
              </div>
              <div className="mt-6">
                <span className="text-6xl font-extrabold leading-[108%] font-nationalBold">Terms</span>
              </div>
              <div className="mt-3">
                <span className="text-[#9C9D9F] font-bold text-base font-national">Effective date: May 18, 2023</span>
              </div>
              <div className="py-10">
                <div className="grid max-w-xl mt-8">
                  <span className="text-[#252525] text-base font-inter font-bold leading-[125%]">Acceptance of Terms</span>
                  <div className="mt-4 text-[#252525] text-base font-inter leading-[140%]">
                    By accessing and using the Linq mobile application ("App"), you accept and agree to be bound by the terms and provisions of this agreement ("Terms"). In addition, when using the App's particular services, you shall be subject to any posted guidelines or rules applicable to such services. Any participation in this service will constitute acceptance of this agreement. If you do not agree to abide by the above, please do not use the App.
                  </div>
                </div>
                <div className="grid max-w-xl mt-8">
                  <span className="text-[#252525] text-base font-inter font-bold leading-[125%]">The Service</span>
                  <div className="mt-4 text-[#252525] text-base font-inter leading-[140%]">
                    Our Privacy Policy, which outlines our practices towards handling any personal information that you may provide to us, is incorporated into these Terms. For more information, please review our full Privacy Policy.
                  </div>
                </div>
                <div className="grid max-w-xl mt-8">
                  <span className="text-[#252525] text-base font-inter font-bold leading-[125%]">User Conduct</span>
                  <div className="mt-4 text-[#252525] text-base font-inter leading-[140%]">
                    You are solely responsible for your own communications, the consequences of posting those communications, and your reliance on any communications found in the public areas of the App. Linq is not responsible for the consequences of any communications in the public areas.
                  </div>
                </div>
                <div className="grid max-w-xl mt-8">
                  <span className="text-[#252525] text-base font-inter font-bold leading-[125%]">Intellectual Property</span>
                  <div className="mt-4 text-[#252525] text-base font-inter leading-[140%]">
                    You acknowledge and agree that all content and information, including but not limited to text, software, music, sound, photographs, graphics, video or other material contained in sponsor advertisements or information presented to you by Linq, its partners or advertisers, is protected by copyrights, trademarks, service marks, patents, or other proprietary rights and laws.
                  </div>
                </div>
                <div className="grid max-w-xl mt-8">
                  <span className="text-[#252525] text-base font-inter font-bold leading-[125%]">Billing and Payments</span>
                  <div className="mt-4 text-[#252525] text-base font-inter leading-[140%]">
                    In-app purchases and premium subscriptions require a valid payment method. Your transactions with us may be subject to taxes, foreign exchange fees, and other fees, for which you are responsible.
                  </div>
                </div>
                <div className="grid max-w-xl mt-8">
                  <span className="text-[#252525] text-base font-inter font-bold leading-[125%]">Termination</span>
                  <div className="mt-4 text-[#252525] text-base font-inter leading-[140%]">
                    We reserve the right to terminate your use of the App for violation of these Terms or any other reason at our discretion.
                  </div>
                </div>
                <div className="grid max-w-xl mt-8">
                  <span className="text-[#252525] text-base font-inter font-bold leading-[125%]">Changes to the Terms</span>
                  <div className="mt-4 text-[#252525] text-base font-inter leading-[140%]">
                    We may modify these Terms from time to time. If you continue to use the App after modifications, you are indicating that you agree to be bound by the modified Terms. If you do not agree with the modifications, please cease using the App.
                  </div>
                </div>
                <div className="grid max-w-xl mt-8">
                  <span className="text-[#252525] text-base font-inter font-bold leading-[125%]">Governing Law</span>
                  <div className="mt-4 text-[#252525] text-base font-inter leading-[140%]">
                    These Terms shall be governed by and construed in accordance with the laws of the jurisdiction in which our company is established, without regard to its conflict of law provisions.
                  </div>
                </div>
                <div className="grid max-w-xl mt-8">
                  <span className="text-[#252525] text-base font-inter font-bold leading-[125%]">Contact Us</span>
                  <div className="mt-4 text-[#252525] text-base font-inter leading-[140%]">
                    If you have any questions about these Terms, please contact us at: <a href="mailto: support@letslinq.co" className="text-[#3872e0]">support@letslinq.co</a><br />
                    By using Linq, you acknowledge that you have read these Terms of Service and agree to be bound by them.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="md:flex lg:hidden sm:flex">
        <section id="example-2" className="h-[100vh] bg-white pb-10 w-full">
          <div className="flex justify-between items-center py-8 px-6 fixed w-full z-10 bg-gradient-to-b from-white via-white to-transparent">
            <div>
              <img src={require("../assets/images/green_logo.png")} alt="logo" width={50} height={50} />
            </div>
            <div>
              <span className="text-[32px] font-extrabold font-nationalBold">Terms</span>
            </div>
            <div>
              <div className="flex justify-end">
                <a href="/" className="bg-[#E5E5E5] rounded-full w-[40px] h-[40px] text-center">
                  <span className="text-3xl text-black align-super">&times;</span>
                </a>
              </div>
            </div>
          </div>
          <div className="overflow-auto pt-8 px-6">
            <div className="mt-10 pt-8">
              <span className="text-[#9C9D9F] font-bold text-base font-national">Effective date: May 18, 2023</span>
            </div>
            <div className="py-10">
              <div className="grid w-full mt-8">
                <span className="text-[#252525] text-base font-inter font-bold leading-[125%]">Acceptance of Terms</span>
                <div className="mt-4 text-[#252525] text-base font-inter leading-[140%]">
                  By accessing and using the Linq mobile application ("App"), you accept and agree to be bound by the terms and provisions of this agreement ("Terms"). In addition, when using the App's particular services, you shall be subject to any posted guidelines or rules applicable to such services. Any participation in this service will constitute acceptance of this agreement. If you do not agree to abide by the above, please do not use the App.
                </div>
              </div>
              <div className="grid w-full mt-8">
                <span className="text-[#252525] text-base font-inter font-bold leading-[125%]">The Service</span>
                <div className="mt-4 text-[#252525] text-base font-inter leading-[140%]">
                  Our Privacy Policy, which outlines our practices towards handling any personal information that you may provide to us, is incorporated into these Terms. For more information, please review our full Privacy Policy.
                </div>
              </div>
              <div className="grid w-full mt-8">
                <span className="text-[#252525] text-base font-inter font-bold leading-[125%]">User Conduct</span>
                <div className="mt-4 text-[#252525] text-base font-inter leading-[140%]">
                  You are solely responsible for your own communications, the consequences of posting those communications, and your reliance on any communications found in the public areas of the App. Linq is not responsible for the consequences of any communications in the public areas.
                </div>
              </div>
              <div className="grid w-full mt-8">
                <span className="text-[#252525] text-base font-inter font-bold leading-[125%]">Intellectual Property</span>
                <div className="mt-4 text-[#252525] text-base font-inter leading-[140%]">
                  You acknowledge and agree that all content and information, including but not limited to text, software, music, sound, photographs, graphics, video or other material contained in sponsor advertisements or information presented to you by Linq, its partners or advertisers, is protected by copyrights, trademarks, service marks, patents, or other proprietary rights and laws.
                </div>
              </div>
              <div className="grid w-full mt-8">
                <span className="text-[#252525] text-base font-inter font-bold leading-[125%]">Billing and Payments</span>
                <div className="mt-4 text-[#252525] text-base font-inter leading-[140%]">
                  In-app purchases and premium subscriptions require a valid payment method. Your transactions with us may be subject to taxes, foreign exchange fees, and other fees, for which you are responsible.
                </div>
              </div>
              <div className="grid w-full mt-8">
                <span className="text-[#252525] text-base font-inter font-bold leading-[125%]">Termination</span>
                <div className="mt-4 text-[#252525] text-base font-inter leading-[140%]">
                  We reserve the right to terminate your use of the App for violation of these Terms or any other reason at our discretion.
                </div>
              </div>
              <div className="grid w-full mt-8">
                <span className="text-[#252525] text-base font-inter font-bold leading-[125%]">Changes to the Terms</span>
                <div className="mt-4 text-[#252525] text-base font-inter leading-[140%]">
                  We may modify these Terms from time to time. If you continue to use the App after modifications, you are indicating that you agree to be bound by the modified Terms. If you do not agree with the modifications, please cease using the App.
                </div>
              </div>
              <div className="grid w-full mt-8">
                <span className="text-[#252525] text-base font-inter font-bold leading-[125%]">Governing Law</span>
                <div className="mt-4 text-[#252525] text-base font-inter leading-[140%]">
                  These Terms shall be governed by and construed in accordance with the laws of the jurisdiction in which our company is established, without regard to its conflict of law provisions.
                </div>
              </div>
              <div className="grid w-full mt-8">
                <span className="text-[#252525] text-base font-inter font-bold leading-[125%]">Contact Us</span>
                <div className="mt-4 text-[#252525] text-base font-inter leading-[140%]">
                  If you have any questions about these Terms, please contact us at: <a href="mailto: support@letslinq.co" className="text-[#3872e0]">support@letslinq.co</a><br />
                  By using Linq, you acknowledge that you have read these Terms of Service and agree to be bound by them.
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Terms;